import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CoreuiVue from "@coreui/vue";
// import CoreuiVuePro from '@coreui/vue-pro';
// import CoreuiPro from '@coreui/coreui-pro';
import CIcon from "@coreui/icons-vue";
import { iconsSet as icons } from "@/assets/icons";
import DocsExample from "@/components/DocsExample";
import axiosInterceptor from "./utils/axiosInterceptor"; // Sesuaikan dengan struktur direktori Anda

const app = createApp(App);
app.use(store);
app.use(router);
app.use(CoreuiVue);
// app.use(CoreuiVuePro);
// app.use(CoreuiPro);
app.provide("icons", icons);
app.component("CIcon", CIcon);
app.component("DocsExample", DocsExample);

// Panggil instance Axios dengan interceptor setelah membuat instance Vue
axiosInterceptor();

app.mount("#app");
