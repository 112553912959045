import axios from "axios";

// auth.js
const state = {
  accessToken: sessionStorage.getItem("accessToken") || null,
  user: {
    username: sessionStorage.getItem("username") || null,
    jenis_akun: sessionStorage.getItem("role") || null,
    id_user: sessionStorage.getItem("id") || null,
    id_user: sessionStorage.getItem("mhs") || null,
  },
};

const mutations = {
  setToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setUser(state, user) {
    state.user = user;
  },
  clearAuthData(state) {
    state.accessToken = null;
    state.user = {};
  },
};

const actions = {
  async login({ commit }, { username, password }) {
    try {
      // Lakukan permintaan API untuk login
      const response = await axios.post("auth/auth/login", {
        username,
        password,
      });

      // Asumsikan bahwa respons API memiliki bidang 'token' dan 'user'
      const token = response.data.data.token;
      const user = response.data.data.user;

      // Lakukan mutasi untuk memperbarui Vuex
      commit("setToken", token);
      commit("setUser", user);

      // Simpan token di sessionStorage untuk login yang persisten
      sessionStorage.setItem("accessToken", token);
      sessionStorage.setItem("id", user.id_user);
      sessionStorage.setItem("akun", user.jenis_akun);
      if (user.id_mahasiswa !== null) {
        sessionStorage.setItem("mhs", user.id_mahasiswa);
      }
      sessionStorage.setItem("username", user.username);
      sessionStorage.setItem("nama_lengkap", user.nama_lengkap_akun);

      let role;

      switch (user.id_unit) {
        case 1:
          role = "Rektor";
          break;
        case 2:
          role = "Senat Akademik";
          break;
        case 3:
          role = "Wakil Rektor";
          break;
        case 4:
          role = "Fakultas Bisnis";
          break;
        case 5:
          role = "Fakultas Informatika dan Pariwisata";
          break;
        case 6:
          role = "Program Vokasi";
          break;
        case 7:
          role = "Program Pascasarjana";
          break;
        case 8:
          role = "BPM";
          break;
        case 9:
          role = "Satuan Pengawas Internal";
          break;
        case 10:
          role = "LPPM";
          break;
        case 11:
          role = "BAAK";
          break;
        case 12:
          role = "Perpustakaan dan Kearsipan";
          break;
        case 13:
          role = "Laboratorium";
          break;
        case 14:
          role = "Keuangan";
          break;
        case 15:
          role = "BAUM";
          break;
        case 16:
          role = "HRD";
          break;
        case 17:
          role = "Kesejahteraan dan Penggajian";
          break;
        case 18:
          role = "Teknologi Informasi dan Komunikasi";
          break;
        case 19:
          role = "Sekretariat";
          break;
        case 20:
          role = "Marketing";
          break;
        case 21:
          role = "CDC, Kerjasama Lembaga dan Humas";
          break;
        case 22:
          role = "Bimbingan Konseling";
          break;
        case 23:
          role = "Kemahasiswaan";
          break;
        case 24:
          role = "Unit Kesehatan";
          break;
        default:
          role = user.jenis_akun;
          break;
      }

      sessionStorage.setItem("role", role);

      if (user.status_kaprodi == 1) {
        sessionStorage.setItem("role", "kaprodi");
      }

      if (user.status_sekprodi == 1) {
        sessionStorage.setItem("role", "sekprodi");
      }

      if (user.status_dekan == 1) {
        sessionStorage.setItem("role", "dekan");
      }

      window.location.reload();
    } catch (error) {
      console.error("Login gagal", error);
      throw error;
    }
  },

  logout({ commit }) {
    // Lakukan mutasi untuk membersihkan token dari Vuex state
    commit("clearAuthData");
    // Hapus token dari sessionStorage
    sessionStorage.clear();
    window.location.reload();
  },
};

const getters = {
  username: (state) => state.user.username,
  role: (state) => state.user.jenis_akun,
  token: (state) => state.accessToken,
  // tambahkan getters lain jika diperlukan
};

export default {
  state,
  mutations,
  actions,
  getters,
};
