import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_financial = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
      {
        path: "/akun/manageuser",
        name: "Management User",
        component: () => import("@/views/akun/ManagementUser.vue"),
      },
      {
        path: "/akun/managerole",
        name: "Management Role",
        component: () => import("@/views/akun/ManagementRole.vue"),
      },
    ],
  },
  //finansial
  {
    path: "/finansial",
    name: "Finansial",
    component: LayoutCard,
    redirect: "/finansial/datapembayaran",
    children: [
      {
        path: "/finansial/datapembayaran",
        name: "Data Pembayaran",
        component: () =>
          import("@/views/finansial_keu/pembayaranreguler/DataPembayaran.vue"),
      },
      {
        path: "/finansial/detailpembayaran/:id",
        name: "Detail Pembayaran",
        component: () =>
          import(
            "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaran.vue"
          ),
      },
      {
        path: "/finansial/detailpembayaranmala/:id",
        name: "Detail Pembayaran MALA",
        component: () =>
          import(
            "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaranMala.vue"
          ),
      },
      {
        path: "/finansial/laporan",
        name: "Laporan Keuangan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/finansial/laporan/logva",
        children: [
          {
            path: "/finansial/laporan/logva",
            name: "Log Transaksi VA",
            component: () => import("@/views/finansial_keu/laporan/LogVA.vue"),
          },
          {
            path: "/finansial/laporan/laporanpiutangmhs",
            name: "Laporan Piutang Mahasiswa",
            component: () =>
              import(
                "@/views/finansial_keu/laporan/LaporanPiutangMahasiswa.vue"
              ),
          },
          {
            path: "/finansial/laporan/laporanlainnya",
            name: "Laporan Transaksi Lainnya",
            component: () =>
              import("@/views/finansial_keu/laporan/LogTransaksi.vue"),
          },
        ],
      },
      {
        path: "/finansial/master",
        name: "Master Finansial",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/finansial/master/komponenbiaya",
        children: [
          {
            path: "/finansial/master/komponenbiaya",
            name: "Komponen Biaya",
            component: () => import("@/views/adm_akademik/KomponenBiaya.vue"),
          },
          {
            path: "/finansial/master/pengaturankomponenbiaya/:id",
            name: "Pengaturan Komponen Biaya",
            component: () =>
              import("@/views/adm_akademik/PengaturanKomponenBiaya.vue"),
          },
          {
            path: "/finansial/master/masterkomponenbiaya",
            name: "Master Komponen Biaya",
            component: () =>
              import("@/views/adm_akademik/MasterKomponenBiaya.vue"),
          },
        ],
      },
      {
        path: "/finansial/datava",
        name: "Data Virtual Account",
        component: () => import("@/views/finansial_keu/DataVA.vue"),
      },
      {
        path: "/finansial/updateva",
        name: "Update VA",
        component: () => import("@/views/finansial_keu/UpdateVA.vue"),
      },
      {
        path: "/finansial/importpembayaran",
        name: "Import Data Pembayaran",
        component: () =>
          import("@/views/finansial_keu/ImportDataPembayaran.vue"),
      },
    ],
  },
  //beasiswa
  {
    path: "/beasiswa",
    name: "Beasiswa",
    component: LayoutCard,
    redirect: "/beasiswa/lihatjenisbeasiswa",
    children: [
      {
        path: "/beasiswa/lihatjenisbeasiswa",
        name: "Lihat Jenis Beasiswa",
        component: () => import("@/views/beasiswa/LihatJenisBeasiswa.vue"),
      },
      {
        path: "/beasiswa/beasiswamahasiswa",
        name: "Beasiswa Mahasiswa",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            name: "Lihat Jenis Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
            name: "Edit Aturan Beasiswa",
            component: () => import("@/views/beasiswa/EditAturanBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
            name: "Tambah Beasiswa",
            component: () => import("@/views/beasiswa/TambahBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
            name: "Penerima Beasiswa",
            component: () => import("@/views/beasiswa/PenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
            name: "Lihat Penerima Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/importpenerima",
            name: "Import Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/ImportPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
            name: "Approval Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
            name: "Approval Status Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalStatusPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/beasiswamaba",
        name: "Potongan Mahasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamaba/jenispotongan",
            name: "Potongan Calon Mahasiswa",
            component: () =>
              import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
            name: "Lihat Jenis Potongan",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMaba.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
            name: "Lihat Penerima Potongan",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/laporanbeasiswa",
        name: "Laporan Beasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
        children: [
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
            name: "Laporan Beasiswa Per Semester",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
            name: "Laporan Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
            name: "Laporan Per Prodi",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpotongan",
            name: "Laporan Potongan",
            component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
          },
        ],
      },
    ],
  },

  //registrasi
  {
    path: "/registrasi",
    name: "Registrasi",
    component: LayoutCard,
    redirect: "/registrasi/beranda",
    children: [
      {
        path: "/registrasi/beranda",
        name: "Beranda Registrasi",
        component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
      },
      {
        path: "/registrasi/ujian",
        name: "Ujian",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/ujian/cetakkartu",
        children: [
          {
            path: "/registrasi/ujian/cetakkartu",
            name: "Cetak Kartu Ujian",
            component: () =>
              import("@/views/registrasi/ujian/CetakKartuUjian.vue"),
          },
          {
            path: "/registrasi/ujian/ujiansusulan",
            name: "Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/UjianSusulan.vue"),
          },
        ],
      },
    ],
  },
];

export default routes_financial;
