// axiosInterceptor.js

import axios from "axios";

function setupAxiosInterceptor() {
  axios.interceptors.response.use(
    (response) => {
      // Tangani response sukses
      return response;
    },
    (error) => {
      console.error("interceptor error: ", error);
      // handling error 401
      if (error?.response?.status == 401) {
        const currentRoute = window.location.hash;
        if (
          currentRoute !== "#/pages/login" &&
          currentRoute !== "#/pages/admission"
        ) {
          Promise.reject(error);
          return handleUnauthorizedError();
        }
      }
      return Promise.reject(error);
    }
  );

  function handleUnauthorizedError() {
    sessionStorage.clear();
    window.location.reload();
  }

  return axios; // Kembalikan instance Axios
}

export default setupAxiosInterceptor;
