import { h, resolveComponent } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";

import DefaultLayout from "@/layouts/DefaultLayout";
import NotFound from "@/views/pages/Page404";

//routes_role
import routes_financial from "./role/financial";
import routes_dosen from "./role/dosen";
import routes_rektor from "./role/rektor";
import routes_wakil_rektor from "./role/warek";
import routes_baak from "./role/baak";
import routes_marketing from "./role/marketing";
import routes_mahasiswa from "./role/mahasiswa";
import routes_camaba from "./role/camaba";
import routes_superadmin from "./role/superadmin";
import routes_kaprodi from "./role/kaprodi";
import routes_nonmahasiswa from "./role/nonmahasiswa";
import routes_fakultas from "./role/fakultas";

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

const addRoleRoutes = (role, layoutComponent, routes) => {
  return {
    path: "/",
    name: "Home",
    component: layoutComponent,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      },
      ...routes,
    ],
  };
};

const role = sessionStorage.getItem("role")
  ? sessionStorage.getItem("role").toLowerCase()
  : "";

const jenis_akun = sessionStorage.getItem("akun")
  ? sessionStorage.getItem("akun").toLowerCase()
  : "";

// Mapping roles to route configurations
const routesMap = {
  dosen: routes_dosen,
  rektor: routes_rektor,
  "wakil rektor": routes_wakil_rektor,
  baak: routes_baak,
  marketing: routes_marketing,
  keuangan: routes_financial,
  mahasiswa: routes_mahasiswa,
  camaba: routes_camaba,
  superadmin: routes_superadmin,
  kaprodi: routes_kaprodi,
  dekan: routes_kaprodi,
  sekprodi: routes_kaprodi,
  "fakultas informatika dan pariwisata": routes_fakultas,
  "fakultas bisnis": routes_fakultas,
  "fakultas vokasi": routes_fakultas,
  "program vokasi": routes_fakultas,
};

const defaultRoute = routes_nonmahasiswa;

// Choose the appropriate routes based on the role
let roleRoutes = addRoleRoutes(
  role,
  DefaultLayout,
  routesMap[role] || defaultRoute
);

if (jenis_akun === "dosen" && role !== "dosen") {
  roleRoutes.children = [...roleRoutes.children, ...routes_dosen];
}

console.log("route", roleRoutes);

// Add a generic 404 route
const notFoundRoute = {
  path: "/:pathMatch(.*)*",
  name: "not-found",
  component: NotFound,
};

const authRoute = {
  path: "/pages",
  redirect: "/pages/404",
  name: "Pages",
  component: {
    render() {
      return h(resolveComponent("router-view"));
    },
  },
  children: [
    {
      path: "404",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },

    {
      path: "500",
      name: "Page500",
      component: () => import("@/views/pages/Page500"),
    },

    {
      path: "admission",
      name: "Admission",
      component: () => import("@/views/pages/Admission"),
    },

    {
      path: "dela",
      name: "Dela",
      component: () => import("@/views/pages/Dela"),
    },
    {
      path: "login",
      name: "Login",
      component: () => import("@/views/pages/Login"),
      beforeEnter: (to, from, next) => {
        const token = sessionStorage.getItem("accessToken");
        if (token) {
          // Jika pengguna sudah login, arahkan ke halaman beranda
          next("/");
        } else {
          // Jika pengguna belum login, lanjutkan ke halaman login
          next();
        }
      },
      meta: { requiresAuth: false },
    },
    {
      path: "parentslogin",
      name: "ParentsLogin",
      component: () => import("@/views/pages/ParentsLogin"),
    },
    {
      path: "register",
      name: "Register",
      component: () => import("@/views/pages/Register"),
    },
  ],
};

// Add the routes to the router
router.addRoute(roleRoutes);
router.addRoute(notFoundRoute);
router.addRoute(authRoute);

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("accessToken");
  const isLoginPage = to.path === "/pages/login";
  const isAdmissionPage = to.path === "/pages/admission";

  // Jika bukan rute /pages/login dan tidak ada token, arahkan ke halaman login
  if (!isLoginPage && !token && !isAdmissionPage) {
    next("/pages/login");
  } else {
    next(); // Lanjutkan navigasi
  }
});

export default router;
