export default [
  //akun
  {
    component: "CNavGroup",
    name: "Nilai Dosen",
    to: "/nilaidosen",
    items: [
      {
        component: "CNavItem",
        name: "Beranda Nilai",
        to: "/nilaidosen/berandanilai",
      },
      {
        component: "CNavItem",
        name: "Riwayat Input Nilai",
        to: "/nilaidosen/riwayatinput",
      },
      {
        component: "CNavItem",
        name: "List Mata Kuliah",
        to: "/nilaidosen/listmatkul",
      },
    ],
  },
  {
    component: "CNavGroup",
    name: "Presensi",
    to: "/presensi/bap",
    items: [
      // {
      //   component: "CNavGroup",
      //   name: "Kehadiran Mahasiswa",
      //   to: "/presensi/kehadiranmhs",
      //   items: [
      //     {
      //       component: "CNavItem",
      //       name: "Kehadiran per Perkuliahan",
      //       to: "/presensi/kehadiranmhs/per-perkuliahan",
      //     },
      //   ],
      // },
      {
        component: "CNavItem",
        name: "Cetak Daftar Hadir",
        to: "/presensi/cetakdaftarhadir",
      },
      {
        component: "CNavItem",
        name: "Jadwal Dosen",
        to: "/presensi/jadwaldosen",
      },
      {
        component: "CNavItem",
        name: "Berita Acara Perkuliahan",
        to: "/presensi/bap",
      },
      {
        component: "CNavItem",
        name: "Evaluasi Pembelajaran",
        to: "/presensi/evaluasipembelajaran",
      },
      {
        component: "CNavItem",
        name: "Rekap Kehadiran Dosen",
        to: "/presensi/laporanperkuliahan/rekapkehadirandosen",
      },
    ],
  },

  //nilai
  // {
  //   component: "CNavGroup",
  //   name: "Nilai",
  //   to: "/nilai",
  //   items: [
  //     {
  //       component: "CNavItem",
  //       name: "Beranda Nilai",
  //       to: "/nilai/beranda",
  //     },
  //     {
  //       component: "CNavGroup",
  //       name: "KHS",
  //       to: "/nilai/khs",
  //       items: [
  //         {
  //           component: "CNavItem",
  //           name: "Pilih Mahasiswa",
  //           to: "/nilai/khs/pilih-mahasiswa",
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
];
