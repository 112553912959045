<template>
  <CSidebar
    position="fixed"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
    class="sidebar"
  >
    <CSidebarBrand>
      <img
        src="../assets/images/kisLogoPutih.png"
        height="50"
        class="m-4 rounded"
        custom-class-name="sidebar-brand-full"
      />
    </CSidebarBrand>
    <!-- <CFormInput
      type="text"
      placeholder="Search Menu"
      aria-label="lg input example"
      class="m-2 input-search"
      style="width: auto"
    /> -->
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { AppSidebarNav } from './AppSidebarNav';
import { CImage } from '@coreui/vue';
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
    // eslint-disable-next-line vue/no-unused-components
    CImage,
  },
  setup() {
    const store = useStore();
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    };
  },
};
</script>

<style>
.sidebar-nav {
  background-color: #846aad !important;
}

.sidebar-brand {
  background-color: #846aad !important;
}
</style>
