import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_fakultas = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },

  //adm akademik
  {
    path: "/adm-akademik",
    name: "Administrasi Akademik",
    component: LayoutCard,
    redirect: "/adm-akademik/beranda",
    children: [
      {
        path: "/adm-akademik/beranda",
        name: "Beranda Adm Akademik",
        component: () => import("@/views/adm_akademik/Beranda.vue"),
      },
      {
        path: "/adm-akademik/kabarberita",
        name: "Kabar Berita",
        component: () => import("@/views/adm_akademik/KabarBerita.vue"),
      },
      {
        path: "/adm-akademik/master",
        name: "Master",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/adm-akademik/master/kurikulum",
        children: [
          {
            path: "/adm-akademik/master/kurikulum",
            name: "Tahun Kurikulum",
            component: () => import("@/views/adm_akademik/Kurikulum.vue"),
          },
          {
            path: "/adm-akademik/master/thnajar",
            name: "Tahun Ajar",
            component: () => import("@/views/adm_akademik/TahunAjar.vue"),
          },
          {
            path: "/adm-akademik/master/semester",
            name: "Semester",
            component: () => import("@/views/adm_akademik/Semester.vue"),
          },
          {
            path: "/adm-akademik/master/fakultas",
            name: "Fakultas",
            component: () => import("@/views/adm_akademik/Fakultas.vue"),
          },
          {
            path: "/adm-akademik/master/prodi",
            name: "Program Studi",
            component: () => import("@/views/adm_akademik/Prodi.vue"),
          },
          {
            path: "/adm-akademik/master/dosen",
            name: "Dosen",
            component: () => import("@/views/adm_akademik/Dosen.vue"),
          },
          {
            path: "/adm-akademik/master/kelas",
            name: "Kelas",
            component: () => import("@/views/adm_akademik/Kelas.vue"),
          },
          {
            path: "/adm-akademik/master/kelas-praktikum",
            name: "Kelas Praktikum",
            component: () => import("@/views/adm_akademik/KelasPraktikum.vue"),
          },
          {
            path: "/adm-akademik/master/ruangan",
            name: "Ruangan",
            component: () => import("@/views/adm_akademik/Ruangan.vue"),
          },
          {
            path: "/adm-akademik/master/waktu",
            name: "Waktu",
            component: () => import("@/views/adm_akademik/Waktu.vue"),
          },
          {
            path: "/adm-akademik/master/shift",
            name: "Shift",
            component: () => import("@/views/adm_akademik/Shift.vue"),
          },
          {
            path: "/adm-akademik/master/kodematkul",
            name: "Kode Mata Kuliah",
            component: () => import("@/views/adm_akademik/KodeMatkul.vue"),
          },
          {
            path: "/adm-akademik/master/komponennilai",
            name: "Komponen Penialaian",
            component: () => import("@/views/adm_akademik/KomponenNilai.vue"),
          },
        ],
      },
      {
        path: "/adm-akademik/pengaturan",
        name: "Pengaturan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/adm-akademik/pengaturan/jadwalkrs",
        children: [
          {
            path: "/adm-akademik/pengaturan/jadwalkrs",
            name: "Jadwal Registrasi KRS",
            component: () => import("@/views/adm_akademik/JadwalRegisKRS.vue"),
          },
          {
            path: "/adm-akademik/pengaturan/jadwalpmb",
            name: "Jadwal Registrasi PMB",
            component: () => import("@/views/adm_akademik/JadwalRegisPMB.vue"),
          },
          {
            path: "/adm-akademik/pengaturan/jadwalinputnilai",
            name: "Jadwal Input Nilai",
            component: () =>
              import("@/views/adm_akademik/JadwalInputNilai.vue"),
          },
        ],
      },
    ],
  },

  //beasiswa
  {
    path: "/beasiswa",
    name: "Beasiswa",
    component: LayoutCard,
    redirect: "/beasiswa/lihatjenisbeasiswa",
    children: [
      {
        path: "/beasiswa/lihatjenisbeasiswa",
        name: "Lihat Jenis Beasiswa",
        component: () => import("@/views/beasiswa/LihatJenisBeasiswa.vue"),
      },
      {
        path: "/beasiswa/beasiswamahasiswa",
        name: "Beasiswa Mahasiswa",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            name: "Lihat Jenis Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
            name: "Edit Aturan Beasiswa",
            component: () => import("@/views/beasiswa/EditAturanBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
            name: "Tambah Beasiswa",
            component: () => import("@/views/beasiswa/TambahBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
            name: "Penerima Beasiswa",
            component: () => import("@/views/beasiswa/PenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
            name: "Lihat Penerima Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/importpenerima",
            name: "Import Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/ImportPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
            name: "Approval Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
            name: "Approval Status Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalStatusPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/beasiswamaba",
        name: "Potongan Mahasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamaba/jenispotongan",
            name: "Potongan Calon Mahasiswa",
            component: () =>
              import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
            name: "Lihat Jenis Potongan",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMaba.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
            name: "Lihat Penerima Potongan",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/laporanbeasiswa",
        name: "Laporan Beasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
        children: [
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
            name: "Laporan Beasiswa Per Semester",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
            name: "Laporan Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
            name: "Laporan Per Prodi",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpotongan",
            name: "Laporan Potongan",
            component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
          },
        ],
      },
    ],
  },

  //kurikulum
  {
    path: "/kurikulum",
    name: "Kurikulum",
    component: LayoutCard,
    redirect: "/kurikulum/beranda",
    children: [
      {
        path: "/kurikulum/beranda",
        name: "Beranda Kurikulum Kaprodi",
        component: () => import("@/views/kurikulum_kaprodi/Beranda.vue"),
      },
      {
        path: "/kurikulum/kelolakurikulum",
        name: "Kelola Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/kelolakurikulum/lihatkurikulum",
        children: [
          {
            path: "/kurikulum/kelolakurikulum/lihatkurikulum",
            name: "Lihat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/tambahmatkul",
            name: "Tambah Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/TambahMataKuliah.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/peminatanprodi",
            name: "Kelola Peminatan Prodi",
            component: () =>
              import("@/views/kurikulum_kaprodi/KelolaPeminatanProdi.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/impormatkul",
            name: "Impor Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/ImporMatkul.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/editmatakuliah/:id",
            name: "Detail Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/EditMataKuliah.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/persetujuankurikulum",
        name: "Persetujuan Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
        children: [
          {
            path: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
            name: "Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/RiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detail/:id_kurikulum/:id_prodi",
            name: "Detail Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailRiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detailmatkul/:id",
            name: "Detail Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailMatkul.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/aturapproval",
            name: "Atur Approval",
            component: () =>
              import("@/views/kurikulum_kaprodi/AturApproval.vue"),
          },
          {
            path: "/kurikulum/logkurikulum/:id",
            name: "Log Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LogKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/pilihtipe/:id",
            name: "Pilih Tipe",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/PilihTipe.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/editmatkul/:id",
            name: "Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/Edit.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/silabus",
        name: "Silabus Kaprodi",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/silabus/aturrps",
        children: [
          {
            path: "/kurikulum/silabus/aturrps",
            name: "Atur RPS Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/AturRPS.vue"),
          },
          {
            path: "/kurikulum/silabus/aturrps/aturgbpp/:id",
            name: "Atur GBPP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/AturGBPP.vue"),
          },
          {
            path: "/kurikulum/silabus/aturrps/atursap/:id",
            name: "Atur SAP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/AturSAP.vue"),
          },
          {
            path: "/kurikulum/silabus/lihatrps",
            name: "Lihat RPS Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/LihatRPS.vue"),
          },
          {
            path: "/kurikulum/silabus/lihatrps/lihatgbpp/:id",
            name: "Lihat GBPP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/LihatGBPP.vue"),
          },
          {
            path: "/kurikulum/silabus/lihatrps/lihatsap/:id",
            name: "Lihat SAP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/LihatSAP.vue"),
          },
          {
            path: "/kurikulum/silabus/statusinputrps",
            name: "Status Input RPS Kaprodi",
            component: () =>
              import("@/views/kurikulum_kaprodi/StatusInputRPS.vue"),
          },
          {
            path: "/kurikulum/silabus/ubahrps",
            name: "Ubah RPS Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/UbahRPS.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/laporanmatkul",
        name: "Laporan Mata Kuliah",
        component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      },
      {
        path: "/kurikulum/laporanmatkul/:id",
        name: "Laporan Matkul",
        component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      },
      {
        path: "/kurikulum/usermanual",
        name: "User Manual",
        component: () => import("@/views/kurikulum_kaprodi/ManualKaprodi.vue"),
      },
    ],
  },

  //registrasi
  {
    path: "/registrasi",
    name: "Registrasi",
    component: LayoutCard,
    redirect: "/registrasi/beranda",
    children: [
      {
        path: "/registrasi/beranda",
        name: "Beranda Registrasi",
        component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
      },
      {
        path: "/registrasi/pilihmahasiswa",
        name: "Pilih Mahasiswa",
        component: () => import("@/views/Registrasi_Kaprodi/Mahasiswa.vue"),
      },
      {
        path: "/registrasi/editkrs/:id/:prodi",
        name: "Edit KRS",
        component: () => import("@/views/Registrasi_Kaprodi/EditKRS.vue"),
      },
      {
        path: "/registrasi/registrasimatkulacc/:id",
        name: "Registrasi Mata Kuliah ACC",
        component: () => import("@/views/registrasi/RegistrasiMatkul.vue"),
      },
      {
        path: "/registrasi/jadwal",
        name: "Jadwal",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/jadwal/jadwalujian",
        children: [
          {
            path: "/jadwal/tambahjadwal",
            name: "Tambah Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/TambahJadwal.vue"),
          },
          {
            path: "/jadwal/lihatjadwal",
            name: "lihat Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/LihatJadwal.vue"),
          },
          {
            path: "/jadwal/daftarmahasiswamatkul/:id",
            name: "Daftar Mahasiswa Mata Kuliah",
            component: () =>
              import("@/views/Jadwal_Kaprodi/DaftarMahasiswaMatkul.vue"),
          },
          {
            path: "/jadwal/editjadwal/:id",
            name: "Edit Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/EditJadwal.vue"),
          },
          // {
          //   path: '/jadwal/ruangkosong',
          //   name: 'Ruang Kosong',
          //   component: () => import('@/views/Jadwal_Kaprodi/RuangKosong.vue'),
          // },
          {
            path: "/jadwal/importjadwal",
            name: "Import Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
          },
        ],
      },
      {
        path: "/registrasi/ujian",
        name: "Ujian",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/ujian/jadwalujian",
        children: [
          {
            path: "/registrasi/ujian/jadwalujian",
            name: "Jadwal Ujian",
            component: () => import("@/views/registrasi/ujian/JadwalUjian.vue"),
          },
          {
            path: "/registrasi/ujian/importjadwalujian",
            name: "Import Jadwal Ujian",
            component: () =>
              import("@/views/Jadwal_Kaprodi/ImportJadwalUjian.vue"),
          },
          {
            path: "/registrasi/ujian/ujiansusulan",
            name: "Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/UjianSusulan.vue"),
          },
          {
            path: "/registrasi/ujian/registrasiujiansusulan/:",
            name: "Registrasi Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/RegistrasiUjianSusulan.vue"),
          },
          {
            path: "/registrasi/ujian/cetakdokumen",
            name: "Cetak Dokumen Ujian",
            component: () =>
              import("@/views/registrasi/ujian/CetakDokumen.vue"),
          },
          {
            path: "/registrasi/ujian/cetakdokumensusulan",
            name: "Cetak Dokumen Ujian Susulan",
            component: () =>
              import("@/views/registrasi/ujian/CetakDokumenSusulan.vue"),
          },
        ],
      },
      {
        path: "/registrasi/matkul",
        name: "Mata Kuliah ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/matkul/daftarmatkul",
        children: [
          {
            path: "/registrasi/matkul/daftarmatkul",
            name: "Daftar Mata Kuliah",
            component: () =>
              import("@/views/Registrasi_Kaprodi/DaftarMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/tambahmatkul",
            name: "Tambah Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/TambahMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/impormatkul",
            name: "Impor Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/ImporMataKuliah.vue"),
          },
          {
            path: "/registrasi/matkul/alokasimatkul",
            name: "Alokasi Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/AlokasiMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/ubahmatkul/:id",
            name: "Ubah Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/UbahMatkul.vue"),
          },
          {
            path: "/registrasi/matkul/koordinatormatkul",
            name: "Koordinator Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/KoordinatorMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/statusmatkul",
            name: "Status Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/StatusMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/tamatkul",
            name: "Mendaftarkan  Mata Kuliah TA/PA",
            component: () => import("@/views/Registrasi_Kaprodi/TaMatKul.vue"),
          },
        ],
      },
    ],
  },

  //kuesioner
  {
    path: "/kuesioner",
    name: "Kuesioner",
    component: LayoutCard,
    redirect: "/kuesioner/beranda",
    children: [
      {
        path: "/kuesioner/beranda",
        name: "Beranda Kuesioner",
        component: () => import("@/views/kuesioner/Beranda.vue"),
      },
      {
        path: "/kuesioner/lihat-kuesioner",
        name: "Lihat Kuesioner",
        component: () => import("@/views/kuesioner/LihatKuesioner.vue"),
      },
      {
        path: "/kuesioner/detail-kuesioner/:id",
        name: "Detail Kuesioner",
        component: () => import("@/views/kuesioner/DetailKuesioner.vue"),
      },
    ],
  },
];

export default routes_fakultas;
