import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_camaba = [
  //pmb camaba
  {
    path: "/pmb-camaba",
    name: "PMB CAMABA",
    component: LayoutCard,
    redirect: "/pmb-camaba/beranda",
    children: [
      {
        path: "/pmb-camaba/uploadpernyataan",
        name: "Upload Pernyataan Keuangan",
        component: () => import("@/views/Camaba/Beranda.vue"),
      },
      {
        path: "/pmb-camaba/beranda",
        name: "Beranda",
        component: () => import("@/views/Camaba/AlurSistem.vue"),
      },
      {
        path: "/pmb-camaba/statuspembayaran",
        name: "Status Pembayaran",
        component: () => import("@/views/Camaba/StatusPembayaran.vue"),
      },
      {
        path: "/pmb-camaba/mengisidatapeserta",
        name: "Mengisi Data Peserta",
        component: () => import("@/views/Camaba/MengisiDataPeserta.vue"),
      },
      {
        path: "/pmb-camaba/viewdatapeserta",
        name: "View Data Peserta",
        component: () => import("@/views/Camaba/ViewDataPeserta.vue"),
      },
      {
        path: "/pmb-camaba/dokumenpeserta",
        name: "Dokumen Peserta PMB",
        component: () => import("@/views/Camaba/DokumenPesertaPMB.vue"),
      },
      {
        path: "/pmb-camaba/undurdiri",
        name: "Pengajuan Undur Diri Peserta CAMABA",
        component: () => import("@/views/Camaba/PengajuanUndurDiri.vue"),
      },
      {
        path: "/pmb-camaba/undurdiri/:id",
        name: "Undur Diri Peserta",
        component: () => import("@/views/Camaba/UndurDiri.vue"),
      },
      {
        path: "/pmb-camaba/cetakdokumen",
        name: "Cetak Dokumen",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb-camaba/cetakdokumen/beritaacara",
        children: [
          {
            path: "/pmb-camaba/cetakdokumen/beritaacara",
            name: "Berita Acara",
            component: () => import("@/views/Camaba/BeritaAcara.vue"),
          },
          {
            path: "/pmb-camaba/cetakdokumen/statusdok",
            name: "Status Dokumen",
            component: () => import("@/views/Camaba/StatusDokumen.vue"),
          },
        ],
      },
    ],
  },
];

export default routes_camaba;
