import LayoutCard from "@/layouts/LayoutCard";
import { h, resolveComponent } from "vue";

const routes_wakil_rektor = [
  //akun
  {
    path: "/akun",
    name: "Akun",
    component: LayoutCard,
    redirect: "/akun/beranda",
    children: [
      {
        path: "/akun/beranda",
        name: "Beranda Akun",
        component: () => import("@/views/akun/Beranda.vue"),
      },
      {
        path: "/akun/profilku",
        name: "Profilku",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/akun/profilku/editprofil",
        children: [
          {
            path: "/akun/profilku/ubahpassword",
            name: "Ubah Password",
            component: () => import("@/views/akun/UbahPassword.vue"),
          },
        ],
      },
    ],
  },

  //beasiswa
  {
    path: "/beasiswa",
    name: "Beasiswa",
    component: LayoutCard,
    redirect: "/beasiswa/lihatjenisbeasiswa",
    children: [
      {
        path: "/beasiswa/lihatjenisbeasiswa",
        name: "Lihat Jenis Beasiswa",
        component: () => import("@/views/beasiswa/LihatJenisBeasiswa.vue"),
      },
      {
        path: "/beasiswa/beasiswamahasiswa",
        name: "Beasiswa Mahasiswa",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamahasiswa/jenisbeasiswa",
            name: "Lihat Jenis Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/editaturan/:id",
            name: "Edit Aturan Beasiswa",
            component: () => import("@/views/beasiswa/EditAturanBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/tambahbeasiswa",
            name: "Tambah Beasiswa",
            component: () => import("@/views/beasiswa/TambahBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswa",
            name: "Penerima Beasiswa",
            component: () => import("@/views/beasiswa/PenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/penerimabeasiswamhs",
            name: "Lihat Penerima Beasiswa Mahasiswa",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswaMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/importpenerima",
            name: "Import Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/ImportPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalbeasiswa",
            name: "Approval Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamahasiswa/aprovalstatusbeasiswa",
            name: "Approval Status Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/AprovalStatusPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/beasiswamaba",
        name: "Potongan Mahasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/beasiswamaba/beasiswacalonmahasiswa",
        children: [
          {
            path: "/beasiswa/beasiswamaba/jenispotongan",
            name: "Potongan Calon Mahasiswa",
            component: () =>
              import("@/views/beasiswa/BeasiswaCalonMahasiswa.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatjenisbeasiswamaba",
            name: "Lihat Jenis Potongan",
            component: () =>
              import("@/views/beasiswa/LihatJenisBeasiswaMaba.vue"),
          },
          {
            path: "/beasiswa/beasiswamaba/lihatpenerimabeasiswa",
            name: "Lihat Penerima Potongan",
            component: () =>
              import("@/views/beasiswa/LihatPenerimaBeasiswa.vue"),
          },
        ],
      },
      {
        path: "/beasiswa/laporanbeasiswa",
        name: "Laporan Beasiswa ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/beasiswa/laporanbeasiswa/laporanbeasiswamaba",
        children: [
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswapersemester",
            name: "Laporan Beasiswa Per Semester",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerSemester.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpenerimabeasiswa",
            name: "Laporan Penerima Beasiswa",
            component: () =>
              import("@/views/beasiswa/LaporanPenerimaBeasiswa.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanbeasiswaperprodi",
            name: "Laporan Per Prodi",
            component: () =>
              import("@/views/beasiswa/LaporanBeasiswaPerProdi.vue"),
          },
          {
            path: "/beasiswa/laporanbeasiswa/laporanpotongan",
            name: "Laporan Potongan",
            component: () => import("@/views/beasiswa/LaporanPotongan.vue"),
          },
        ],
      },
    ],
  },

  //data-mahasiswa
  {
    path: "/data-mahasiswa",
    name: "Data Mahasiswa",
    component: LayoutCard,
    redirect: "/data-mahasiswa/perubahan-data",
    children: [
      {
        path: "/data-mahasiswa/perubahan-data",
        name: "Perubahan Data",
        component: () => import("@/views/DataMahasiswa/PerubahanData.vue"),
      },
      {
        path: "/data-mahasiswa/perubahan-data/:id",
        name: "Detail Perubahan Data",
        component: () =>
          import("@/views/DataMahasiswa/DetailPerubahanData.vue"),
      },
      {
        path: "/data-mahasiswa/transkrip",
        name: "Transkrip",
        component: () => import("@/views/DataMahasiswa/Transkrip.vue"),
      },
    ],
  },

  //finansial
  {
    path: "/finansial",
    name: "Finansial",
    component: LayoutCard,
    redirect: "/finansial/datapembayaran",
    children: [
      {
        path: "/finansial/datapembayaran",
        name: "Data Pembayaran",
        component: () =>
          import("@/views/finansial_keu/pembayaranreguler/DataPembayaran.vue"),
      },
      {
        path: "/finansial/detailpembayaran/:id",
        name: "Detail Pembayaran",
        component: () =>
          import(
            "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaran.vue"
          ),
      },
      {
        path: "/finansial/detailpembayaranmala/:id",
        name: "Detail Pembayaran MALA",
        component: () =>
          import(
            "@/views/finansial_keu/pembayaranreguler/DetailDataPembayaranMala.vue"
          ),
      },
      {
        path: "/finansial/laporan",
        name: "Laporan Keuangan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/finansial/laporan/logva",
        children: [
          {
            path: "/finansial/laporan/logva",
            name: "Log Transaksi VA",
            component: () => import("@/views/finansial_keu/laporan/LogVA.vue"),
          },
          {
            path: "/finansial/laporan/laporanpiutangmhs",
            name: "Laporan Piutang Mahasiswa",
            component: () =>
              import(
                "@/views/finansial_keu/laporan/LaporanPiutangMahasiswa.vue"
              ),
          },
          {
            path: "/finansial/laporan/laporanlainnya",
            name: "Laporan Transaksi Lainnya",
            component: () =>
              import("@/views/finansial_keu/laporan/LogTransaksi.vue"),
          },
        ],
      },
      {
        path: "/finansial/master",
        name: "Master Finansial",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/finansial/master/komponenbiaya",
        children: [
          {
            path: "/finansial/master/komponenbiaya",
            name: "Komponen Biaya",
            component: () => import("@/views/adm_akademik/KomponenBiaya.vue"),
          },
          {
            path: "/finansial/master/pengaturankomponenbiaya/:id",
            name: "Pengaturan Komponen Biaya",
            component: () =>
              import("@/views/adm_akademik/PengaturanKomponenBiaya.vue"),
          },
          {
            path: "/finansial/master/masterkomponenbiaya",
            name: "Master Komponen Biaya",
            component: () =>
              import("@/views/adm_akademik/MasterKomponenBiaya.vue"),
          },
        ],
      },
      {
        path: "/finansial/datava",
        name: "Data Virtual Account",
        component: () => import("@/views/finansial_keu/DataVA.vue"),
      },
      {
        path: "/finansial/updateva",
        name: "Update VA",
        component: () => import("@/views/finansial_keu/UpdateVA.vue"),
      },
      {
        path: "/finansial/importpembayaran",
        name: "Import Data Pembayaran",
        component: () =>
          import("@/views/finansial_keu/ImportDataPembayaran.vue"),
      },
    ],
  },

  //kurikulum
  {
    path: "/kurikulum",
    name: "Kurikulum",
    component: LayoutCard,
    redirect: "/kurikulum/beranda",
    children: [
      {
        path: "/kurikulum/beranda",
        name: "Beranda Kurikulum Kaprodi",
        component: () => import("@/views/kurikulum_kaprodi/Beranda.vue"),
      },
      {
        path: "/kurikulum/kelolakurikulum",
        name: "Kelola Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/kelolakurikulum/lihatkurikulum",
        children: [
          {
            path: "/kurikulum/kelolakurikulum/lihatkurikulum",
            name: "Lihat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LihatKurikulum.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/tambahmatkul",
            name: "Tambah Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/TambahMataKuliah.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/peminatanprodi",
            name: "Kelola Peminatan Prodi",
            component: () =>
              import("@/views/kurikulum_kaprodi/KelolaPeminatanProdi.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/impormatkul",
            name: "Impor Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/ImporMatkul.vue"),
          },
          {
            path: "/kurikulum/kelolakurikulum/editmatakuliah/:id",
            name: "Detail Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/EditMataKuliah.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/persetujuankurikulum",
        name: "Persetujuan Kurikulum",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
        children: [
          {
            path: "/kurikulum/persetujuankurikulum/riwayatkurikulum",
            name: "Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/RiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detail/:id_kurikulum/:id_prodi",
            name: "Detail Riwayat Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailRiwayatKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/detailmatkul/:id",
            name: "Detail Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/DetailMatkul.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/aturapproval",
            name: "Atur Approval",
            component: () =>
              import("@/views/kurikulum_kaprodi/AturApproval.vue"),
          },
          {
            path: "/kurikulum/logkurikulum/:id",
            name: "Log Kurikulum",
            component: () =>
              import("@/views/kurikulum_kaprodi/LogKurikulum.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/pilihtipe/:id",
            name: "Pilih Tipe",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/PilihTipe.vue"),
          },
          {
            path: "/kurikulum/persetujuankurikulum/editmatkul/:id",
            name: "Edit Mata Kuliah",
            component: () =>
              import("@/views/kurikulum_kaprodi/riwayat/Edit.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/silabus",
        name: "Silabus Kaprodi",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/kurikulum/silabus/aturrps",
        children: [
          {
            path: "/kurikulum/silabus/aturrps",
            name: "Atur RPS Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/AturRPS.vue"),
          },
          {
            path: "/kurikulum/silabus/aturrps/aturgbpp/:id",
            name: "Atur GBPP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/AturGBPP.vue"),
          },
          {
            path: "/kurikulum/silabus/aturrps/atursap/:id",
            name: "Atur SAP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/AturSAP.vue"),
          },
          {
            path: "/kurikulum/silabus/lihatrps",
            name: "Lihat RPS Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/LihatRPS.vue"),
          },
          {
            path: "/kurikulum/silabus/lihatrps/lihatgbpp/:id",
            name: "Lihat GBPP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/LihatGBPP.vue"),
          },
          {
            path: "/kurikulum/silabus/lihatrps/lihatsap/:id",
            name: "Lihat SAP Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/LihatSAP.vue"),
          },
          {
            path: "/kurikulum/silabus/statusinputrps",
            name: "Status Input RPS Kaprodi",
            component: () =>
              import("@/views/kurikulum_kaprodi/StatusInputRPS.vue"),
          },
          {
            path: "/kurikulum/silabus/ubahrps",
            name: "Ubah RPS Kaprodi",
            component: () => import("@/views/kurikulum_kaprodi/UbahRPS.vue"),
          },
        ],
      },
      {
        path: "/kurikulum/laporanmatkul",
        name: "Laporan Mata Kuliah",
        component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      },
      {
        path: "/kurikulum/laporanmatkul/:id",
        name: "Laporan Matkul",
        component: () => import("@/views/kurikulum_kaprodi/LaporanMatkul.vue"),
      },
      {
        path: "/kurikulum/usermanual",
        name: "User Manual",
        component: () => import("@/views/kurikulum_kaprodi/ManualKaprodi.vue"),
      },
    ],
  },

  //nilai dosen
  {
    path: "/nilaidosen",
    name: "Nilai Dosen",
    component: LayoutCard,
    redirect: "/nilaidosen/berandanilai",
    children: [
      {
        path: "/nilaidosen/berandanilai",
        name: "Beranda_Nilai ",
        component: () => import("@/views/Nilai_Dosen/Beranda.vue"),
      },
      {
        path: "/nilaidosen/riwayatinput",
        name: "Riwayat Input Nilai ",
        component: () => import("@/views/Nilai_Dosen/Riwayat.vue"),
      },
      {
        path: "/nilaidosen/listmatkul",
        name: "List Mata Kuliah ",
        component: () => import("@/views/Nilai_Dosen/ListMatkul.vue"),
      },
      {
        path: "/nilaidosen/inputnilai/:id",
        name: "Input Nilai ",
        component: () => import("@/views/Nilai_Dosen/InputNilai.vue"),
      },
      {
        path: "/nilaidosen/print-berita-acara/:id",
        name: "Cetak Berita Acara",
        component: () => import("@/views/Nilai_Dosen/PrintBeritaAcara.vue"),
      },
    ],
  },

  //nilai
  {
    path: "/nilai",
    name: "Nilai",
    component: LayoutCard,
    redirect: "/nilai/beranda",
    children: [
      {
        path: "/nilai/beranda",
        name: "Beranda Nilai",
        component: () => import("@/views/nilai/Beranda.vue"),
      },
      {
        path: "/nilai/khs",
        name: "KHS",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/nilai/khs/pilih-mahasiswa",
        children: [
          {
            path: "/nilai/khs/pilih-mahasiswa",
            name: "Pilih Nilai Mahasiswa",
            component: () => import("@/views/nilai/PilihMahasiswa.vue"),
          },
          {
            path: "/nilai/khs/lihatnilai/:id",
            name: "Lihat Nilai",
            component: () => import("@/views/nilai/LihatNilai.vue"),
          },
        ],
      },
    ],
  },

  //pmb
  {
    path: "/pmb",
    name: "PMB",
    component: LayoutCard,
    redirect: "/pmb/beranda",
    children: [
      {
        path: "/pmb/beranda",
        name: "Beranda PMB",
        component: () => import("@/views/PMB/Beranda.vue"),
      },
      {
        path: "/pmb/pengunduranDiri",
        name: "Form Pengunduran Diri",
        component: () => import("@/views/PMB/pengunduranDiri.vue"),
      },
      {
        path: "/pmb/daftarPeserta",
        name: "Daftar Peserta",
        component: () => import("@/views/PMB/daftarPeserta.vue"),
      },
      {
        path: "/pmb/viewdatapeserta/:id",
        name: "View Data Peserta",
        component: () => import("@/views/PMB/ViewDataPeserta.vue"),
      },
      {
        path: "/pmb/mengisidatapeserta/:id",
        name: "Mengisi Data Peserta",
        component: () => import("@/views/PMB/MengisiDataPeserta.vue"),
      },
      {
        path: "/pmb/daftarPesertaOnSite",
        name: "Daftar Peserta Registrasi On Site",
        component: () => import("@/views/PMB/daftarPesertaOnSite.vue"),
      },
      {
        path: "/pmb/registrasi/check/:id_mahasiswa/:id_tagihan",
        name: "Registrasi Check",
        component: () => import("@/views/pmb_adm/RegistrasiCheck.vue"),
      },

      {
        path: "/pmb/pembayaran",
        name: "Pembayaran PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/pembayaran/InputPembayaran",
        children: [
          {
            path: "/pmb/pembayaran/beasiswa",
            name: "Beasiswa PMB",
            component: () => import("@/views/PMB/pembayaran/Beasiswa.vue"),
          },
          {
            path: "/pmb/pembayaran/InputPembayaran",
            name: "Input Pembayaran",
            component: () =>
              import("@/views/PMB/pembayaran/InputPembayaran.vue"),
          },
          {
            path: "/pmb/statuspembayaran/:id",
            name: "Status Pembayaran",
            component: () => import("@/views/PMB/StatusPembayaran.vue"),
          },
          {
            path: "/pmb/pembayaran/KonfirmasiPembayaran",
            name: "Konfirmasi Pembayaran PMB",
            component: () =>
              import("@/views/PMB/pembayaran/KonfirmasiPembayaran.vue"),
          },
          {
            path: "/pmb/pembayaran/pengembalian",
            name: "Pengembalian Pembayaran Keuangan",
            component: () =>
              import("@/views/pmb_keu/pembayaran/Pengembalian.vue"),
          },
        ],
      },
      {
        path: "/pmb/Laporan",
        name: "Laporan",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/laporan",
        children: [
          {
            path: "/pmb/Laporan/Agama",
            name: "Laporan Distribusi Agama",
            component: () => import("@/views/PMB/Laporan/Agama.vue"),
          },
          {
            path: "/pmb/Laporan/LaporanRegistrasiPerTanggal",
            name: "Laporan Registrasi Per Tanggal",
            component: () =>
              import("@/views/PMB/Laporan/LaporanRegistrasiPerTanggal.vue"),
          },
          {
            path: "/pmb/Laporan/laporanPembayaran",
            name: "Laporan Pembayaran PMB",
            component: () =>
              import("@/views/PMB/Laporan/laporanPembayaran.vue"),
          },
          {
            path: "/pmb/Laporan/laporanPembayaranCetakBA",
            name: "Laporan Pemabayaran Cetak BA",
            component: () =>
              import("@/views/PMB/Laporan/laporanPembayaranCetakBA.vue"),
          },
          {
            path: "/pmb/Laporan/LaporanOnsite",
            name: "Laporan Registrasi On Site per Tanggal",
            component: () => import("@/views/PMB/Laporan/LaporanOnsite.vue"),
          },
          {
            path: "/pmb/Laporan/LaporanKontakPeserta",
            name: "Laporan Kontak Peserta",
            component: () => import("@/views/PMB/Laporan/LaporanKontak.vue"),
          },
          {
            path: "/pmb/Laporan/PesertaPmb",
            name: "Laporan Peserta PMB",
            component: () => import("@/views/PMB/Laporan/PesertaPmb.vue"),
          },
          {
            path: "/pmb/Laporan/LaporanDistribusiJK",
            name: "Laporan Distribusi Jenis Kelamin",
            component: () =>
              import("@/views/PMB/Laporan/LaporanDistribusiJK.vue"),
          },
          {
            path: "/pmb/Laporan/DokumenRegonsite",
            name: "Laporan Dokumen Regonsite",
            component: () => import("@/views/PMB/Laporan/DokumenRegonsite.vue"),
          },
        ],
      },

      {
        path: "/pmb/pengaturan",
        name: "Pengaturan PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/pengaturan",
        children: [
          {
            path: "/pmb/pengaturan/ListDokumen",
            name: "List Dokumen Peserta PMB",
            component: () => import("@/views/PMB/Pengaturan/ListDokumen.vue"),
          },
          {
            path: "/pmb/pengaturan/InputFinnet",
            name: "Input pembayaran Finnet",
            component: () => import("@/views/PMB/Pengaturan/InputFinnet.vue"),
          },
          {
            path: "/pmb/pengaturan/Manual",
            name: "Download Manual Pengguna",
            component: () => import("@/views/PMB/Bantuan/Manual.vue"),
          },
          {
            path: "/pmb/pengaturan/generetekelas",
            name: "Generate Kelas",
            component: () => import("@/views/PMB/Pengaturan/GenereteKelas.vue"),
          },
          {
            path: "/pmb/pengaturan/jadwalseleksireg",
            name: "Jadwal Seleksi Registrasi",
            component: () =>
              import("@/views/PMB/Pengaturan/JadwalRegistrasi.vue"),
          },
        ],
      },
      {
        path: "/pmb/bantuan",
        name: "Bantuan PMB",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/pmb/bantuan",
        children: [
          {
            path: "/pmb/bantuan/Manual",
            name: "Download Manual Pengguna",
            component: () => import("@/views/PMB/Bantuan/Manual.vue"),
          },
        ],
      },
    ],
  },

  //presensi
  {
    path: "/presensi",
    name: "Presensi",
    component: LayoutCard,
    redirect: "/presensi/beranda",
    children: [
      {
        path: "/presensi/beranda",
        name: "Beranda Presensi",
        component: () => import("@/views/presensi/Beranda.vue"),
      },
      {
        path: "/presensi/rfidlog",
        name: "RFID Log",
        component: () => import("@/views/presensi/RFIDLog.vue"),
      },
      {
        path: "/presensi/kehadiran",
        name: "Kehadiran2",
        component: () => import("@/views/perkuliahan/Kehadiran.vue"),
      },

      {
        path: "/presensi/kehadiranmhs",
        name: "Kehadiran Mahasiswa",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/presensi/kehadiranmhs/per-perkuliahan",
        children: [
          {
            path: "/presensi/kehadiranmhs/per-perkuliahan",
            name: "Kehadiran per Perkuliahan",
            component: () =>
              import("@/views/Dosen/presensi/KehadiranperPerkuliahan.vue"),
          },
        ],
      },
      {
        path: "/presensi/bap",
        name: "Berita Acara Perkuliahan",
        component: () => import("@/views/Dosen/presensi/BAP.vue"),
      },
      {
        path: "/presensi/submit-bap/:id",
        name: "Submit BAP",
        component: () => import("@/views/Dosen/presensi/SubmitBAP.vue"),
      },
      {
        path: "/presensi/cetakdaftarhadir",
        name: "Cetak Daftar Hadir",
        component: () => import("@/views/Dosen/presensi/CetakDaftarHadir.vue"),
      },
      {
        path: "/presensi/evaluasipembelajaran",
        name: "Evaluasi Pembelajaran",
        component: () =>
          import("@/views/Dosen/presensi/EvaluasiPembelajaran.vue"),
      },
      {
        path: "/presensi/honor",
        name: "Honor Kehadiran",
        component: () => import("@/views/Dosen/presensi/HonorKehadiran.vue"),
      },
      {
        path: "/presensi/jadwaldosen",
        name: "Jadwal Dosen Presensi",
        component: () => import("@/views/Dosen/presensi/JadwalDosen.vue"),
      },
      {
        path: "/presensi/daftarmahasiswamk/:id_mt/:id_jadwal",
        name: "Daftar Mahasiswa Mata Kuliah",
        component: () => import("@/views/Dosen/presensi/DaftarMahasiswaMK.vue"),
      },
      {
        path: "/presensi/laporanperkuliahan/rekapkehadirandosen",
        name: "Rekap Kehadiran Dosen",
        component: () =>
          import("@/views/Presensi_Kaprodi/RekapKehadiranDosen.vue"),
      },
    ],
  },

  //registrasi
  {
    path: "/registrasi",
    name: "Registrasi",
    component: LayoutCard,
    redirect: "/registrasi/beranda",
    children: [
      {
        path: "/registrasi/beranda",
        name: "Beranda Registrasi",
        component: () => import("@/views/Registrasi_Kaprodi/Beranda.vue"),
      },
      {
        path: "/registrasi/pilihmahasiswa",
        name: "Pilih Mahasiswa",
        component: () => import("@/views/Registrasi_Kaprodi/Mahasiswa.vue"),
      },
      {
        path: "/registrasi/registrasimatkulacc/:id",
        name: "Registrasi Mata Kuliah ACC",
        component: () => import("@/views/registrasi/RegistrasiMatkul.vue"),
      },
      {
        path: "/registrasi/jadwal",
        name: "Jadwal",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/jadwal/jadwalujian",
        children: [
          {
            path: "/registrasi/jadwal/jadwalujian",
            name: "Lihat Jadwal Ujian",
            component: () => import("@/views/registrasi/LihatJadwalUjian.vue"),
          },
          // {
          //   path: "/registrasi/jadwal/importjadwalujian",
          //   name: "Import Jadwal Ujian",
          //   component: () => import("@/views/registrasi/ImportJadwalUjian.vue"),
          // },
          {
            path: "/jadwal/tambahjadwal",
            name: "Tambah Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/TambahJadwal.vue"),
          },
          {
            path: "/jadwal/lihatjadwal",
            name: "lihat Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/LihatJadwal.vue"),
          },
          {
            path: "/jadwal/daftarmahasiswamatkul/:id",
            name: "Daftar Mahasiswa Mata Kuliah",
            component: () =>
              import("@/views/Jadwal_Kaprodi/DaftarMahasiswaMatkul.vue"),
          },
          {
            path: "/jadwal/editjadwal/:id",
            name: "Edit Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/EditJadwal.vue"),
          },
          // {
          //   path: '/jadwal/ruangkosong',
          //   name: 'Ruang Kosong',
          //   component: () => import('@/views/Jadwal_Kaprodi/RuangKosong.vue'),
          // },
          {
            path: "/jadwal/importjadwal",
            name: "Import Jadwal",
            component: () => import("@/views/Jadwal_Kaprodi/ImportJadwal.vue"),
          },
        ],
      },
      {
        path: "/registrasi/matkul",
        name: "Mata Kuliah ",
        component: {
          render() {
            return h(resolveComponent("router-view"));
          },
        },
        redirect: "/registrasi/matkul/daftarmatkul",
        children: [
          {
            path: "/registrasi/matkul/daftarmatkul",
            name: "Daftar Mata Kuliah",
            component: () =>
              import("@/views/Registrasi_Kaprodi/DaftarMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/tambahmatkul",
            name: "Tambah Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/TambahMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/impormatkul",
            name: "Impor Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/ImporMataKuliah.vue"),
          },
          {
            path: "/registrasi/matkul/alokasimatkul",
            name: "Alokasi Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/AlokasiMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/ubahmatkul/:id",
            name: "Ubah Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/UbahMatkul.vue"),
          },
          {
            path: "/registrasi/matkul/koordinatormatkul",
            name: "Koordinator Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/KoordinatorMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/statusmatkul",
            name: "Status Mata Kuliah ",
            component: () =>
              import("@/views/Registrasi_Kaprodi/StatusMatKul.vue"),
          },
          {
            path: "/registrasi/matkul/tamatkul",
            name: "Mendaftarkan  Mata Kuliah TA/PA",
            component: () => import("@/views/Registrasi_Kaprodi/TaMatKul.vue"),
          },
        ],
      },
    ],
  },
];

export default routes_wakil_rektor;
