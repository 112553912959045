<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0 text-white">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="canBeClicked(item) ? item.path : ''"
      :active="item.active"
      class="text-white fw-semibold white"
      style="cursor: pointer"
    >
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from "vue";
import router from "@/router";

export default {
  name: "AppBreadcrumb",
  setup() {
    const breadcrumbs = ref();

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        };
      });
    };

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs();
    });

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs();
    });

    return {
      breadcrumbs,
    };
  },

  computed: {
    canBeClicked() {
      return (item) => {
        // Tidak bisa diklik jika item aktif atau path mengandung "?" atau ":"
        return (
          !item.active && !item.path.includes("?") && !item.path.includes(":")
        );
      };
    },
  },
};
</script>

<style>
.white,
a {
  color: white;
  font-weight: normal;
  text-decoration: none;
}

.white,
a:hover {
  color: #d8c8ef;
  font-weight: 600;
}

.white,
li::before {
  color: white !important;
}
</style>
